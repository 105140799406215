<template>
  <CCard>
    <CModal :show.sync="op_confirm.show" :centered="true" :title="$t(op_confirm.title)" size="lg">
      {{ $t(op_confirm.message) }}
      <template #footer>
        <CButton type="button" class="mr-2" :color="op_confirm.color_yes || 'primary'" @click="onModalConfirmed(op_confirm.response_yes || true)">{{ $t(op_confirm.yes || 'Yes') }}</CButton>
        <CButton v-if="op_confirm.no" type="button" :color="op_confirm.color_no || 'default'" @click="onModalConfirmed(op_confirm.response_no || false)">{{ $t(op_confirm.no || 'No') }}</CButton>
        <CButton v-if="op_confirm.cancel !== false" type="button" :color="op_confirm.color_cancel || 'secondary'" @click="onModalConfirmed(op_confirm.response_cancel)">{{ $t('Cancel') }}</CButton>
      </template>
    </CModal>

    <CCardHeader>{{ entity.building_identifier }} </CCardHeader>
    <CCardBody>
      <CForm>
        <div class="card pb-2" v-for="recipient of recipients.filter(item => item.type !== 'others')" :key="'C' + recipient.contact.id">
          <div class="card-header">
            [{{ contact_types[recipient.type_id].name }}] <a :href="`/admin/property/building/contacts/${recipient.contact.id}/edit`">{{ recipient.contact.name }}</a>
          </div>
          <!--div class="offset-3 col-8 input-group mt-2" v-for="email of recipient.contact.emails" :key="'TO' + email.email"-->
          <div class="offset-3 col-8 input-group mt-2" v-for="email of recipient.emails" :key="'TO' + email.email">
            <input readonly class="form-control" :value="email.email" />
            <div class="input-group-append p-2">
              <CInputCheckbox custom :checked.sync="email.checked_1" />
            </div>
          </div>
        </div>

        <div v-if="is_buildings" class="card">
          <div class="card-header">{{ $t('Recipients') }}</div>
          <div class="card-body">
            <AMultiSelect name="contact_types" v-model="form.contact_types" label="Directed to" :multiple="true" :options="building_options.contact_types" />
            <AMultiSelect name="properties" :horizontal="{ input: 'col-sm-5' }" v-model="form.properties" label="Properties" :closeOnSelect="false" :multiple="true" :options="properties" :config="{ select_all: true }" />
            <template v-if="only_current">
              <AMultiSelect name="send_to" :horizontal="{ input: 'col-sm-6' }" label="Send to" v-model="form.send_to" :options="building_options.apply_options" :helper="form.send_to ? objects.apply_options[form.send_to].details : ''" />
              <AMultiSelect v-if="form.send_to && form.send_to !== 'ALL'" name="buildings" :horizontal="{ input: 'col-sm-6' }" v-model="form.buildings" label="Buildings" :closeOnSelect="false" :multiple="true" :sercheable="true" :options="building_options.buildings" :config="{ label: 'identifier', select_all: true }" />
              <!--AMultiSelect name="property_units" :horizontal="{ input: 'col-sm-5' }" v-model="form.property_units" label="Units" :closeOnSelect="false" :multiple="true" :options="building_options.property_units" :config="{ select_all: true }" /-->
            </template>
            <AInputDatetime :horizontal="{ input: 'col-sm-8' }" name="send_at" label="Send at" type="datetime" v-model="form.send_at" :helper="form.send_at ? '' : 'Immediately'" :min-datetime="$moment().toISOString()" :use12-hour="true" :minute-step="5" value-zone="local" zone="local" format="dd-MM-yyyy hh:mm a" :reset="true" />
          </div>
        </div>

        <div class="card">
          <div class="card-header">{{ $t('Copy to') }}</div>
          <div class="card-body">
            <ul class="list-group list-group-flush">
              <li class="list-group-item" v-for="recipient of recipients.filter(item => item.type === 'others')" :key="recipient.name">
                <div class="input-group">
                  <label class="col-3 col-form-label">{{ $t(recipient.name) }}</label>
                  <input type="email" :readonly="recipient.readonly" class="col-8 form-control" v-model="recipient.emails[0].email" />
                  <div class="input-group-append p-2">
                    <CInputCheckbox custom :disabled="!recipient.emails[0].email" :checked.sync="recipient.emails[0].checked_2" />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div v-if="!is_buildings" class="card pb-2">
          <div class="card-header">{{ $t('Subject prefix') }}</div>
          <div class="card-body">
            <div class="offset-3 col-8 input-group mt-2"><input class="form-control" v-model="prefix" /></div>
          </div>
        </div>
      </CForm>
    </CCardBody>
    <CCardFooter>
      <CButton type="button" class="float-right" color="secondary" @click="onCancel">{{ $t('Cancel') }}</CButton>
      <CButton type="button" class="float-right mr-2" color="primary" :disabled="is_disabled" @click="onSubmit">{{ $t(config.send_button || 'Send email') }}</CButton>
    </CCardFooter>
  </CCard>
</template>

<script>
import viewMixin from '@/app/views/_mixins/view-mixin'

export default {
  name: 'ModalEmail',
  mixins: [viewMixin],
  inheritAttrs: true,
  props: {
    entity: {
      type: Object,
      required: true
    },
    recipients: {
      type: Array,
      required: true
    },
    contact_types: {
      type: Object
      //required: true
    },
    building_options: {
      type: Object
    },
    subject_prefix: {
      type: String,
      default: '' // TODO: trans ??
    },
    config: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    checked() {
      //return this.recipients.filter(item => item.checked)
      const checked = []
      for (const recipient of this.recipients) {
        for (const email of recipient.emails) {
          if (email.checked_1 || email.checked_2) checked.push({ type: recipient.type, email: email.email })
        }
      }
      return checked
    },
    is_disabled() {
      if (this.is_buildings && this.form.contact_types.length && (this.form.send_to === 'ALL' || this.form.buildings.length)) return false
      return !this.checked.length
    },
    properties() {
      return this.userExtra.properties || []
    },
    only_current() {
      return this.form.properties.length === 1 && this.form.properties[0] === this.loggedUser.property.id
    }
  },
  data() {
    return {
      close: false,
      prefix: this.subject_prefix,
      objects: {},
      is_buildings: false,
      apply_options: [
        { id: 'ALL', name: 'All' },
        { id: 'INC', name: 'Include' },
        { id: 'EXC', name: 'Exclude' }
      ],
      form: {
        send_at: '',
        send_to: 'ALL',
        buildings: [],
        properties: [],
        contact_types: [],
        property_units: []
      },

      op_confirm: { show: false, title: 'Send email', message: 'Are you sure you want to send the email?', value: {} }
    }
  },
  created() {
    //console.log(this.loggedUser)
    this.form.properties = [this.loggedUser.property.id]
    if (this.building_options) {
      this.objects.apply_options = {}
      for (const send_option of this.building_options.apply_options || []) {
        this.objects.apply_options[send_option.id] = send_option
      }

      this.form.property_units = []
      for (const property_unit of this.building_options.property_units || []) {
        this.form.property_units.push(property_unit.id)
      }

      this.form.contact_types = [1, 2] // Primary and secondary owners // TODO: dynamic ?
      this.is_buildings = !this.building_options.is_buildings || true
    }
  },
  methods: {
    onCancel() {
      this.op_confirm.show = false
      this.$emit('parentHide', false)
    },
    onModalConfirmed(response) {
      this.op_confirm.show = false
      if (response) this.$emit('modalUpdated', { prefix: this.prefix, checked: this.checked, form: this.form })
    },
    onSubmit() {
      this.op_confirm.show = true
    }
  }
}
</script>
